//header数据
const headerData = {
  home: "首页",
  product: "主营业务",
  server: "配套服务",
  case: "行业案例",
  about: "关于我们",
  contact: "联系我们",
};

export default {
  headerData,
};
