import { createStore } from "vuex";
import cnData from "@/assets/config/cn.js";
import enData from "@/assets/config/en.js";

const state = () => ({
  data: cnData,
  language: "ENGISH",
});

const getters = {
  data: (state) => state.data,
  language: (state) => state.language,
};

const mutations = {
  toggleLanguage(state) {
    state.language = state.language != "ENGLISH" ? "ENGLISH" : "中文";
  },
  setData(state) {
    state.data = state.language != "ENGLISH" ? cnData : enData;
  },
};

const actions = {
  toggleLanguage({ commit }) {
    commit("toggleLanguage");
    commit("setData");
  },
  setData({ commit }) {
    commit("setData");
  },
};

export default createStore({
  state,
  getters,
  mutations,
  actions,
});
