<template>
  <div class="header df" :style="style" :class="{ color: color }">
    <!-- <header-language></header-language> -->
    <div class="fl text-left">
      <router-link
        class="nav-link custom-nav-link color-black fs-20"
        :to="{ name: 'Home' }"
      >
        <img src="../assets/img/home/logo.png" />
        <!-- Shanghai Lead Speed international Logistics Co.,LTD. -->
        深如美设（上海）供应链科技有限公司
      </router-link>
    </div>
    <div class="fr">
      <el-menu
        class="el-menu-demo el-menu-min-1200"
        mode="horizontal"
        :default-active="activeMenu"
        :ellipsis="false"
      >
        <!-- <template v-for="item in headerData" :key="item">
          <el-menu-item :index="item.id">{{ item.name }}</el-menu-item>
        </template> -->
        <el-menu-item index="Home">
          <router-link class="nav-link custom-nav-link" :to="{ name: 'Home' }">
            {{ headerData.home }}
          </router-link>
        </el-menu-item>
        <el-menu-item index="Product">
          <router-link
            class="nav-link custom-nav-link"
            :to="{ name: 'Product' }"
          >
            {{ headerData.product }}
          </router-link>
        </el-menu-item>
        <el-menu-item index="Server">
          <router-link
            class="nav-link custom-nav-link"
            :to="{ name: 'Server' }"
          >
            {{ headerData.server }}
          </router-link>
        </el-menu-item>
        <el-menu-item index="Case">
          <router-link class="nav-link custom-nav-link" :to="{ name: 'Case' }">
            {{ headerData.case }}
          </router-link>
        </el-menu-item>
        <!-- <el-menu-item index="2">
          <router-link
            class="nav-link custom-nav-link"
            :to="{ name: 'Home_' }"
          >
            Services
          </router-link>
        </el-menu-item> -->
        <el-menu-item index="About">
          <router-link class="nav-link custom-nav-link" :to="{ name: 'About' }">
            {{ headerData.about }}
          </router-link>
        </el-menu-item>
        <el-menu-item index="Contact">
          <router-link
            class="nav-link custom-nav-link"
            :to="{ name: 'Contact' }"
          >
            {{ headerData.contact }}
          </router-link>
        </el-menu-item>
        <!-- <el-menu-item index="5" class="last-li-email">
          <a href="mailto:12345@163.com"
            ><img src="../assets/img/home/youjian.svg" alt="" width="22" />
            <span>Email</span>
          </a>
        </el-menu-item> -->
      </el-menu>
      <ul class="ul-menu-max-1200">
        <li>
          <p>
            <img src="../assets/img/home/caidan.svg" alt="" width="18" />
          </p>
          <div>
            <ul>
              <li>
                <router-link
                  class="nav-link custom-nav-link"
                  :to="{ name: 'Home' }"
                >
                  {{ headerData.home }}
                </router-link>
              </li>
              <li>
                <router-link
                  class="nav-link custom-nav-link"
                  :to="{ name: 'Product' }"
                >
                  {{ headerData.product }}
                </router-link>
              </li>
              <li>
                <router-link
                  class="nav-link custom-nav-link"
                  :to="{ name: 'Server' }"
                >
                  {{ headerData.server }}
                </router-link>
              </li>
              <li>
                <router-link
                  class="nav-link custom-nav-link"
                  :to="{ name: 'Case' }"
                >
                  {{ headerData.case }}
                </router-link>
              </li>
              <li>
                <router-link
                  class="nav-link custom-nav-link"
                  :to="{ name: 'About' }"
                >
                  {{ headerData.about }}
                </router-link>
              </li>
              <li>
                <router-link
                  class="nav-link custom-nav-link"
                  :to="{ name: 'Contact' }"
                >
                  {{ headerData.contact }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <!--  <li class="last-li-email">
          <a href="mailto:12345@163.com"
            ><img
              src="../assets/img/home/youjian_orange.svg"
              alt=""
              width="22"
            />
            <span>Email</span>
          </a>
        </li> -->
      </ul>
    </div>
    <el-backtop :right="100" :bottom="100" />
  </div>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
// import headerLanguage from "./headerLanguage.vue";
export default {
  // components: { headerLanguage },
  setup() {
    const route = useRoute();
    let headerData = computed(() => {
      return store.getters["data"].headerData;
    });

    const activeMenu = computed(() => {
      return route.name;
    });

    return {
      headerData,
      activeMenu,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollbox);
  },
  methods: {
    handleScrollbox() {
      // let scrollTop =
      //   window.pageYOffset ||
      //   document.documentElement.scrollTop ||
      //   document.body.scrollTop;
      // let offsetTop = document.querySelector(".header").offsetTop;
      //设置背景颜色的透明读
      // if (scrollTop) {
      //   this.class = `noneShadow,${scrollTop / (scrollTop + 90)})`;
      //   this.color = true;
      // } else if (scrollTop == 0) {
      //   this.class = "showShadow";
      //   this.color = false;
      // }
    },
  },
};
</script>
<style scoped lang="scss">
.noneShadow {
  box-shadow: none;
}
.showShadow {
  box-shadow: 0px 6px 12px #f1f1f1;
}
</style>
